<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"></PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">換領資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-select
                label="禮品"
                :fieldValue.sync="formData.gift_id"
                :options="giftOptions"
                required
                :readonly="initData.gift_id !== null"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-search-select
                label="用戶名稱"
                :fieldValue.sync="formData.student_id"
                :fieldDisplayValue.sync="selectedUser"
                required
                :options="userOptions"
                @input-search="searchUser($event)"
                :disabled="id !== null"
                searchPlaceholder="搜尋用戶名稱/電話"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-input
                label="數量"
                :fieldValue.sync="formData.amount"
                required
                type="number"
                isIntegerOnly
                :customRules="[v => ($validate.isNumber(v) && parseFloat(v) > 0) || '數量必須大於0']"
                :readonly="id !== null"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-date-picker
                label="換領日期"
                :fieldValue.sync="formData.redeem_date"
                required
                disabledActionButtons
                disablePast
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-time-picker label="換領時間" :fieldValue.sync="formData.redeem_time" required />
            </v-col>

            <v-col cols="12" md="6" v-if="id !== null">
              <form-select label="換領狀態" :fieldValue.sync="formData.redeem_status" :options="$redeemStatusOptions" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">{{
        id === null ? '新增換領' : '儲存'
      }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormSearchSelect from '@/components/form/FormSearchSelect.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'
import FormTimePicker from '@/components/form/FormTimePicker.vue'

export default {
  name: 'RedeemDetail',
  components: {
    FormInput,
    FormSelect,
    FormSearchSelect,
    FormDatePicker,
    FormTimePicker,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    giftId() {
      const id = parseInt(this.$route.params.giftId)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      gift_id: null,
      student_id: null,
      amount: '',
      redeem_date: '',
      redeem_time: '',
      redeem_status: '',
    },

    initData: {
      gift_id: null,
    },

    giftOptions: [],
    selectedUser: '',
    userOptions: [],
    allUsers: [],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getAllGift() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetGifts({ status: 'active' }, user.id, user.token)
        this.giftOptions = data.map(item => {
          return { text: item.name, value: item.id }
        })
      } catch (err) {
        this.$common.error(err)
      }
    },

    async getGift() {
      if (this.giftOptions.length) {
        const found = this.giftOptions.find(el => el.value === this.giftId)
        if (found) {
          this.$store.dispatch('setInitLoading', false)
          this.initData.gift_id = this.giftId
          this.formData.gift_id = this.giftId
          return
        }
      }

      this.$store.dispatch('setInitLoading', false)
      this.setAlert({
        message: 'noData',
        redirect: 'GiftList',
      })
    },

    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetRedeemById(this.id, user.id, user.token)
        this.initData.gift_id = data.gift_id
        this.formData.gift_id = data.gift_id
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.redeem_date.length === 8) {
          this.formData.redeem_date = `${data.redeem_date.substring(0, 4)}-${data.redeem_date.substring(
            4,
            6,
          )}-${data.redeem_date.substring(6, 8)}`
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'RedeemList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'RedeemList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async getUserOptions() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetStudents({}, user.id, user.token)
        this.allUsers = data
        this.userOptions = data.map(item => {
          return { value: item.id, text: item.name ? item.name : item.phone }
        })
      } catch {}
    },
    searchUser(keyword) {
      let founds = this.allUsers
      if (keyword) {
        founds = this.allUsers.filter(
          item =>
            (item.name && item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (item.phone && item.phone.indexOf(keyword) > -1),
        )
      }
      this.userOptions = founds.map(item => {
        return { value: item.id, text: item.name ? item.name : item.phone }
      })
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      const payload = this.$common.duplicateData(this.formData)
      payload['amount'] = parseFloat(payload['amount'])
      const user = this.getCurrentUserData()
      this.$store.dispatch('setLoading', true)
      if (this.$validate.DataValid(this.id)) {
        // edit
        try {
          await this.$Fetcher.UpdateRedeem(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewRedeem(payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'RedeemList',
          })
        } catch (err) {
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    await this.getAllGift()
    await this.getUserOptions()
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else if (this.$validate.DataValid(this.giftId)) {
      this.getGift()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
